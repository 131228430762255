import { ErrorHandler, inject, Injectable, NgZone } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { UpdateReloadDialogComponent } from '@components/update-reload-dialog/update-reload-dialog.component';
import { filter, tap } from 'rxjs/operators';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  public dialog: MatDialog = inject(MatDialog);
  private ngZone: NgZone = inject(NgZone);
  private activatedRoute: ActivatedRoute = inject(ActivatedRoute);

  handleError(error: any): void {
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;
    const moduleFailedMessage = /Failed to fetch dynamically imported module/;
    const importingFailedMessage = /Importing a module script failed/;

    if (
      chunkFailedMessage.test(error.message) ||
      moduleFailedMessage.test(error.message) ||
      importingFailedMessage.test(error.message)
    ) {
      this.ngZone.run(() => {
        const dialogRefDelete = this.dialog.open(UpdateReloadDialogComponent, {
          width: '600px',
          data: {
            customerConfig: this.activatedRoute.snapshot?.children[0]?.data?.customerConfig,
          },
        });

        dialogRefDelete
          .afterClosed()
          .pipe(
            filter(r => !!r),
            tap(() => {
              window.location.reload();
            }),
          )
          .subscribe();
      });
    } else {
      console.log('error', error);
      throw new Error(error);
    }
  }
}
